import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareModules, {
  EasySoftwareModulesType,
} from '../EasySoftwareModules/EasySoftwareModules'

export interface WidgetEasySoftwareModulesType
  extends WidgetBase,
  EasySoftwareModulesType {
  contentType: 'EasySoftwareModules'
}

const WidgetEasySoftwareModules: FunctionComponent<WidgetEasySoftwareModulesType> = (
  props
) => (
  <WidgetContainer {...props.puxWidgetProperties} {...props.metadata} thin>
    <EasySoftwareModules
      displayText={props.displayText}
      modulesItem={props.modulesItem}
    />
  </WidgetContainer>
)

export default WidgetEasySoftwareModules
